<template>
  <v-flex></v-flex>
</template>

<script>
  import {existsInvitationByUserEmail} from "../../common/euprotegido-service";
  import {loadDependentsByBeneficiaryCpf, existsDependentByCpf} from "../../common/contract-service"
  import { mapMutations } from "vuex";

  export default {
    data(){
      return {
        dependents: []
      }
    },
    mounted() {
      this.loadDependents();

    },
    methods: {
      ...mapMutations(["showmsg", "loading",]),
      loadDependents(){
        this.loading(true);

        loadDependentsByBeneficiaryCpf(this.$auth.user().cpf).then(res => {
          this.dependents = res.data;
          this.loading(false);
        }).catch(err => {
          this.showmsg({ text: "Erro ao carregar informações!", type: "error" });
          this.loading(false);
        })
        .finally(() => this.getPath())
      },
      getPath() {
        const temCPF = this.$auth.user() && this.$auth.user().cpf != null;
        const email = this.$auth.user().email;
        existsInvitationByUserEmail(email).then(res => {

          if(res.data === true){
            this.$router.push("/dependenteConexaoEmpresa");
          }else{
            this.verifyExistDependentByCpf(temCPF);
          }
        });
      },
      verifyExistDependentByCpf(temCPF){
        if(temCPF) {
          existsDependentByCpf(this.$auth.user().cpf).then(res => {
            if(res.data === true) {
              this.$router.push("/dependenteConexaoEmpresa");
            }else{
              this.$router.push({name: 'conectarComSuaEmpresa'});
            }
          });
        }else{
          this.$router.push({name: 'conectarComSuaEmpresa'});
        }
      }
    }
  }
</script>
